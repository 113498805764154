<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'bill-payments-points-transfer-error',
  components: {
    BaseIcon,
    BaseMdl,
    BaseButton,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      modal,
    }
  },
}
</script>

<template>
  <base-mdl width="sm">
    <div class="flex justify-center text-fg-error">
      <base-icon size="4xl" svg="warning" />
    </div>
    <div class="mt-5 text-center">
      <span v-if="message">
        {{ message }}
      </span>
      <span v-else>The payment transfer failed. Please contact support.</span>
    </div>

    <template #footer>
      <div class="flex w-full items-center justify-center pt-5">
        <base-button class="mx-2.5 w-1/2" color="primary" @click="modal.hide()">
          Got it
        </base-button>
      </div>
    </template>
  </base-mdl>
</template>
